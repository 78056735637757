// Add strings
/* eslint-disable camelcase */
// eslint-disable-next-line no-undef
gdprCookieNoticeLocales.es = {
    description: 'Usamos cookies para ofrecer una mejor experiencia de navegación, personalizar el contenido y los anuncios, proporcionar funciones de redes sociales y analizar nuestro tráfico. Puedes leer más sobre cómo usamos las cookies y cómo puedes controlarlas haciendo clic en Configuración de cookies.',
    readmore: 'Leer más',
    settings: 'Configuración de cookies',
    accept: 'Aceptar cookies',
    statement: 'Nuestro aviso de cookies',
    save: 'Guardar configuración',
    always_on: 'Siempre activas',
    cookie_essential_title: 'Cookies necesarias',
    cookie_essential_desc: 'Estas cookies son necesarias para que la página web funcione correctamente.',
    cookie_performance_title: 'Cookies de rendimiento',
    cookie_performance_desc: 'Estas cookies son usadas para mejorar el rendimiento de la página web pero no son esenciales. Por ejemplo el idioma predefinido del usuario.',
    cookie_analytics_title: 'Cookies de analítica',
    cookie_analytics_desc: 'Estas cookies nos ayudan a medir cómo los usuarios interactúan con el contenido de la web, lo que nos ayuda a personalizar las páginas web y aplicaciones con el proposito de mejorar la experiencia de usuario.',
    cookie_marketing_title: 'Cookies de marketing',
    cookie_marketing_desc: 'Estas cookies se utilizan para hacer que los mensajes publicitarios sean más relevantes para ti y tus intereses. La intención es mostrar anuncios que sean relevantes y atractivos para el usuario individual y, por lo tanto, más valiosos para los publicadores de la web y anunciantes externos.'
};
