// Load locales
const gdprCookieNoticeLocales = {};


/**
 * GDPR Cookie Notice
 * @param {object} config Config
 */
// eslint-disable-next-line no-unused-vars,require-jsdoc,max-statements
function gdprCookieNotice(config) {
    const namespace = 'gdprcookienotice';
    const pluginPrefix = 'gdpr-cookie-notice';
    const templates = window[`${pluginPrefix}-templates`];
    // eslint-disable-next-line no-undef
    const gdprCookies = Cookies.noConflict();
    let modalLoaded = false;
    let noticeLoaded = false;
    let cookiesAccepted = false;
    const categories = [
        'performance',
        'analytics',
        'marketing'
    ];

    const currentCookieSelection = getCookie();

    const cookieEvent = new CustomEvent("cookieChanged", {
        bubbles: true,
        detail: {
            cookieValue: document.cookie,
            checkChange: () => {
                if (cookieEvent.detail.cookieValue !== document.cookie) {
                    cookieEvent.detail.cookieValue = document.cookie;
                    // eslint-disable-next-line no-magic-numbers
                    return 1;
                }
                // eslint-disable-next-line no-magic-numbers
                return 0;

            },
            listenCheckChange: () => {
                setInterval(() => {
                    // eslint-disable-next-line no-magic-numbers
                    if (cookieEvent.detail.checkChange() === 1) {
                        cookieEvent.detail.changed = true;
                        cookieEvent.target.dispatchEvent(cookieEvent);

                    } else {
                        cookieEvent.detail.changed = false;
                    }
                    // eslint-disable-next-line no-magic-numbers
                }, 1000);
            },
            changed: false
        }
    });

    let cookiesAcceptedEvent = new CustomEvent('gdprCookiesEnabled', {detail: currentCookieSelection});


    // Default config options
    if (!config.locale) {
        config.locale = 'es';
    }

    if (!config.timeout) {
        config.timeout = 500;
    }

    if (!config.domain) {
        config.domain = null;
    }

    if (!config.expiration) {
        config.expiration = 30;
    }



    // Use 'es' locale if current locale doesn't exist
    if (typeof gdprCookieNoticeLocales[config.locale] === 'undefined') {
        config.locale = 'es';
    }

    if (typeof config.localeStrings !== 'undefined') {
        gdprCookieNoticeLocales[config.locale] = {...gdprCookieNoticeLocales[config.locale], ...config.localeStrings}
    }

    // Show cookie bar if needed
    // eslint-disable-next-line no-negated-condition
    if (!currentCookieSelection) {
        showNotice();

        // Accept cookies on page scroll
        if (config.implicit) {
            acceptOnScroll();
        }
        document.dispatchEvent(cookieEvent);

    } else {
        cookiesAcceptedEvent = new CustomEvent('gdprCookiesEnabled', {
            detail: {
                analytics: currentCookieSelection.analytics,
                marketing: currentCookieSelection.marketing,
                performance: currentCookieSelection.performance
            }
        });

        document.dispatchEvent(cookiesAcceptedEvent);

        document.dispatchEvent(cookieEvent);
    }

    document.addEventListener("DOMContentLoaded", (event) => {
        event.target.dispatchEvent(cookieEvent);
    });

    document.addEventListener("cookieChanged", (event) => {
        event.detail.listenCheckChange();
        if (event.detail.changed === true) {

            deleteCookies(currentCookieSelection);
        }
    });

    /**
     * Get gdpr cookie notice stored value
     * @return {undefined|{}} Cookie
     */
    function getCookie() {
        return gdprCookies.getJSON(namespace);
    }

    /**
     * Delete cookies if needed
     * @param {any} savedCookies ¿?¿?
     * @return {void|null} ¿?¿?
     */
    function deleteCookies(savedCookies) {
        // Cuando aún no hay nada configurado en el gestor de cookies
        // if (savedCookies === undefined) {
        if (typeof savedCookies === 'undefined') {
            // eslint-disable-next-line id-length
            for (let i = 0; i < categories.length; i++) {
                if (config[categories[i]]) {
                    // eslint-disable-next-line id-length
                    for (let j = 0; j < config[categories[i]].length; j++) {
                        document.cookie = `${config[categories[i]][j]}=; Path=/; Domain=${config.domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                    }
                }
            }
            return;
        }
        // eslint-disable-next-line id-length
        for (let i = 0; i < categories.length; i++) {
            if (config[categories[i]] && !savedCookies[categories[i]]) {
                // eslint-disable-next-line id-length
                for (let j = 0; j < config[categories[i]].length; j++) {
                    document.cookie = `${config[categories[i]][j]}=; Path=/; Domain=${config.domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                }
            }
        }
    }

    /**
     * Hide cookie notice bar
     * @returns {void}
     */
    function hideNotice() {
        document.documentElement.classList.remove(`${pluginPrefix}-loaded`);
    }

    /**
     * Write gdpr cookie notice's cookies when user accepts cookies
     * @param {boolean} save Save?
     * @return {void}
     */
    function acceptCookies(save) {
        const value = {
            date: new Date(),
            necessary: true,
            performance: true,
            analytics: true,
            marketing: true
        };

        // buildModal() // temp
        // If request was coming from the modal, check for the settings
        if (save) {
            // eslint-disable-next-line id-length
            for (let i = 0; i < categories.length; i++) {
                const input = document.getElementById(`${pluginPrefix}-cookie_${categories[i]}`);
                // eslint-disable-next-line multiline-ternary,no-ternary
                value[categories[i]] = input ? input.checked : true;
            }
        }

        gdprCookies.set(namespace, value, {
            expires: config.expiration,
            domain: config.domain
        });

        deleteCookies(value);

        // Load marketing scripts that only works when cookies are accepted
        cookiesAcceptedEvent = new CustomEvent('gdprCookiesEnabled', {detail: value});
        document.dispatchEvent(cookiesAcceptedEvent);
        hideNotice();

    }

    /**
     * Show the cookie bar
     * @return {boolean} Notice loaded?
     */
    function buildNotice() {
        if (noticeLoaded) {
            return false;
        }

        const noticeHtml = localizeTemplate('bar.html');
        document.body.insertAdjacentHTML('beforeend', noticeHtml);

        // Load click functions
        setNoticeEventListeners();

        // Make sure its only loaded once
        noticeLoaded = true;

        return true;
    }

    /**
     * Show the cookie notice
     * @return {void}
     */
    function showNotice() {
        buildNotice();

        // Show the notice with a little timeout
        setTimeout(() => {
            document.documentElement.classList.add(`${pluginPrefix}-loaded`);
        }, config.timeout);
    }

    /**
     * Localize templates
     * @param {string} template Template name
     * @param {string} prefix Prefix?
     * @return {string|boolean} ¿?¿?
     */
    function localizeTemplate(template, prefix) {
        const str = templates[template];
        const data = gdprCookieNoticeLocales[config.locale];
        if (prefix) {
            // eslint-disable-next-line no-param-reassign
            prefix += '_';
        } else {
            // eslint-disable-next-line no-param-reassign
            prefix = '';
        }

        data.readmorelink = config.statement;

        if (typeof str === 'string' && data instanceof Object) {
            // for (var key in data) { // este for no sé porque estaba no hace nada
            // eslint-disable-next-line id-length
            return str.replace(/({([^}]+)})/g, (i) => {
                const key = i.replace(/{/, '').replace(/}/, '');
                if (key === 'prefix') {
                    // eslint-disable-next-line no-magic-numbers
                    return prefix.slice(0, -1);
                }

                if (data[key]) {
                    return data[key];
                } else if (data[prefix + key]) {
                    return data[prefix + key];
                }

                return i;
            });
            // }
        }

        return false;
    }

    /**
     * Build modal window
     * @return {boolean} Modal loaded?
     */
    function buildModal() {
        if (modalLoaded) {
            return false;
        }

        // Load modal template
        const modalHtml = localizeTemplate('modal.html');

        // Append modal into body
        document.body.insertAdjacentHTML('beforeend', modalHtml);

        // Get empty category list
        const categoryList = document.querySelector(`.${pluginPrefix}-modal-cookies`);

        // Load essential cookies
        categoryList.innerHTML += localizeTemplate('category.html', 'cookie_essential');
        const input = document.querySelector(`.${pluginPrefix}-modal-cookie-input`);
        const label = document.querySelector(`.${pluginPrefix}-modal-cookie-input-switch`);
        label.innerHTML = gdprCookieNoticeLocales[config.locale].always_on;
        label.classList.add(`${pluginPrefix}-modal-cookie-state`);
        label.classList.remove(`${pluginPrefix}-modal-cookie-input-switch`);
        input.remove();

        // Load other categories if needed
        if (config.performance) {
            categoryList.innerHTML += localizeTemplate('category.html', 'cookie_performance');
        }
        if (config.analytics) {
            categoryList.innerHTML += localizeTemplate('category.html', 'cookie_analytics');
        }
        if (config.marketing) {
            categoryList.innerHTML += localizeTemplate('category.html', 'cookie_marketing');
        }

        // Load click functions
        setModalEventListeners();

        // Update checkboxes based on stored info(if any)
        if (currentCookieSelection) {
            document.getElementById(`${pluginPrefix}-cookie_performance`).checked = currentCookieSelection.performance;
            document.getElementById(`${pluginPrefix}-cookie_analytics`).checked = currentCookieSelection.analytics;
            document.getElementById(`${pluginPrefix}-cookie_marketing`).checked = currentCookieSelection.marketing;
        }

        // Make sure modal is only loaded once
        modalLoaded = true;

        return true;
    }

    /**
     * Show modal window
     * @return {void}
     */
    function showModal() {
        buildModal();
        document.documentElement.classList.add(`${pluginPrefix}-show-modal`);
    }

    /**
     * Hide modal window
     * @return {void}
     */
    function hideModal() {
        document.documentElement.classList.remove(`${pluginPrefix}-show-modal`);
    }

    /**
     * Click functions in the notice
     * @return {void}
     */
    function setNoticeEventListeners() {
        // eslint-disable-next-line no-magic-numbers
        const settingsButton = document.querySelectorAll(`.${pluginPrefix}-nav-item-settings`)[0];
        // eslint-disable-next-line no-magic-numbers
        const acceptButton = document.querySelectorAll(`.${pluginPrefix}-nav-item-accept`)[0];

        settingsButton.addEventListener('click', (event) => {
            event.preventDefault();
            showModal();
        });

        acceptButton.addEventListener('click', (event) => {
            event.preventDefault();
            acceptCookies(true);
        });
    }

    /**
     * Click functions in the modal
     * @return {void}
     */
    function setModalEventListeners() {
        // eslint-disable-next-line no-magic-numbers
        const closeButton = document.querySelectorAll(`.${pluginPrefix}-modal-close`)[0];
        const categoryTitles = document.querySelectorAll(`.${pluginPrefix}-modal-cookie-title`);
        // eslint-disable-next-line no-magic-numbers
        const saveButton = document.querySelectorAll(`.${pluginPrefix}-modal-footer-item-save`)[0];

        closeButton.addEventListener('click', () => {
            hideModal();
            return false;
        });

        // eslint-disable-next-line id-length
        for (let i = 0; i < categoryTitles.length; i++) {
            // eslint-disable-next-line func-names
            categoryTitles[i].addEventListener('click', function () {
                this.parentNode.parentNode.classList.toggle('open');
                return false;
            });
        }

        saveButton.addEventListener('click', (event) => {
            event.preventDefault();
            saveButton.classList.add('saved');
            setTimeout(() => {
                saveButton.classList.remove('saved');
                hideModal();

                // eslint-disable-next-line no-magic-numbers
            }, 1000);
            // acceptCookies(true);
        });
    }

    // Settings button on the page somewhere
    const globalSettingsButton = document.querySelectorAll(`.${pluginPrefix}-settings-button`);
    if (globalSettingsButton) {
        // eslint-disable-next-line id-length
        for (let i = 0; i < globalSettingsButton.length; i++) {
            globalSettingsButton[i].addEventListener('click', (event) => {
                event.preventDefault();
                showModal();
            });
        }
    }

    /**
     * Get document height
     * @return {number} Document height
     */
    function getDocHeight() {
        const DOCUMENT = document;
        return Math.max(
            DOCUMENT.body.scrollHeight, DOCUMENT.documentElement.scrollHeight,
            DOCUMENT.body.offsetHeight, DOCUMENT.documentElement.offsetHeight,
            DOCUMENT.body.clientHeight, DOCUMENT.documentElement.clientHeight
        );
    }

    /**
     * Check if at least page is 25% scrolled down
     * @return {boolean} 25% scrolled down?
     */
    function amountScrolled() {
        const winheight = window.innerHeight || (document.documentElement || document.body).clientHeight;
        const docheight = getDocHeight();
        const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
        const trackLength = docheight - winheight;
        // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
        // eslint-disable-next-line no-magic-numbers
        const pctScrolled = Math.floor(scrollTop / trackLength * 100);
        // eslint-disable-next-line no-magic-numbers
        if (pctScrolled > 25 && !cookiesAccepted) {
            cookiesAccepted = true;
            return true;
        }
        return false;
    }

    /**
     * Accept cookies on scroll
     * @return {void}
     */
    function acceptOnScroll() {
        window.addEventListener('scroll', function _listener() {
            if (amountScrolled()) {
                acceptCookies(true);
                window.removeEventListener('click', _listener);
            }
        });
    }

    // eslint-disable-next-line func-names
    gdprCookieNotice.removeCookies = function () {
        deleteCookies(currentCookieSelection);
    };
}
