// Add strings
/* eslint-disable camelcase */
// eslint-disable-next-line no-undef
gdprCookieNoticeLocales.de = {
    description: 'Wir verwenden Cookies, um Ihnen ein besseres Surf-Erlebnis zu gewährleisten, Inhalte und Werbung anzupassen, Funktionen für soziale Medien anzubieten und unseren Datenverkehr zu analysieren. Sie können mehr darüber lesen, wie wir diese Cookies verwenden und wie Sie die Cookies kontrollieren können, indem Sie auf Cookie-Einstellungen klicken.',
    readmore: 'Mehr lesen',
    settings: 'Cookie-Einstellungen',
    accept: 'Cookies akzeptieren',
    statement: 'Unsere Cookie-Erklärung',
    save: 'Einstellungen speichern',
    always_on: 'Immer aktiv',
    cookie_essential_title: 'Notwendige Cookies',
    cookie_essential_desc: 'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website erforderlich.',
    cookie_performance_title: 'Performance Cookies',
    cookie_performance_desc: 'Diese Cookies werden verwendet, um die Leistung der Website zu verbessern, sind aber nicht unbedingt erforderlich. Zum Beispiel die Standardsprache des Benutzers.',
    cookie_analytics_title: 'Analytische Cookies',
    cookie_analytics_desc: 'Diese Cookies helfen uns zu messen, wie Benutzer mit Webinhalten interagieren, was uns hilft, Webseiten und Anwendungen zu personalisieren, um die Benutzererfahrung zu verbessern.',
    cookie_marketing_title: 'Marketing Cookies',
    cookie_marketing_desc: 'Diese Cookies werden verwendet, um Werbung für Sie und Ihre Interessen relevanter zu machen. Ziel ist es, Anzeigen zu zeigen, die für den einzelnen Nutzer relevant und ansprechend sind und daher für Web-Publisher und externe Werbetreibende wertvoller sind.'
};
