// Add strings
/* eslint-disable camelcase */
// eslint-disable-next-line no-undef
gdprCookieNoticeLocales.ca = {
    description: 'Fem servir cookies per oferir una millor experiència de navegació, personalitzar el contingut i els anuncis, proporcionar funcions de xarxes socials i analitzar el nostre trànsit. Pots llegir més sobre com fem servir les cookies i com pots controlar-les fent clic a Configuració de cookies.',
    readmore: 'Llegir més',
    settings: 'Configuració de cookies',
    accept: 'Acceptar cookies',
    statement: 'Nostre avís de cookies',
    save: 'Guardar configuració',
    always_on: 'Sempre actives',
    cookie_essential_title: 'Cookies necessàries',
    cookie_essential_desc: 'Aquestes cookies són necessàries perquè la pàgina web funcioni correctament.',
    cookie_performance_title: 'Cookies de rendiment',
    cookie_performance_desc: 'Aquestes cookies s’utilitzen per millorar el rendiment de la pàgina web però no són essencials. Per exemple l\'idioma per defecte de l\'usuari.',
    cookie_analytics_title: 'Cookies d\'analítica',
    cookie_analytics_desc: 'Aquestes cookies ens ajuden a mesurar com els usuaris interactuen amb el contingut de la web, fet que ens ajuda a personalitzar les pàgines web i aplicacions amb el propòsit de millorar l\'experiència d\'usuari.',
    cookie_marketing_title: 'Cookies de marketing',
    cookie_marketing_desc: 'Aquestes cookies s\'utilitzen per fer que els missatges publicitaris siguin més rellevants per a tu i els teus interessos. La intenció és mostrar anuncis que siguin rellevants i atractius per a l\'usuari individual i, per tant, més valuosos per als publicadors del web i anunciants externs.'
};
